import './Dashboard.css';

function Dashboard() {
  return (
    <>
      <div className="p-7 flex-1 h-screen">
        <h1 className="text-2xl font-semibold">
          Dashboard
        </h1>
      </div>
    </>
  );
}

export default Dashboard;